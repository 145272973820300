import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../../components/breadcrumb'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

export const frontmatter = {
  body: 'Contact Us',
  slug: '/contact/',
  title: 'Contact',
}

export const query = graphql`
  query ContactPageQuery {
    ...SiteInfo
  }
`

export default ({ data }) => {
  const { links } = data.site.siteMetadata

  const page = 'Contact'
  const keywords = [page]
  const trail = [
    {
      name: page,
      slug: links.contact.home,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: page,
    keywords,
    name: page,
    slug: links.contact.home,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={page} />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <form
        action={links.contact.thanks}
        data-netlify="true"
        method="POST"
        name="contact"
      >
        <input
          aria-hidden="true"
          aria-label="Form Name"
          name="form-name"
          type="hidden"
          value="contact"
        />
        <p>
          <label htmlFor="name">
            Your Name:
            <input aria-label="Your Name:" id="name" name="name" type="text" />
          </label>
        </p>
        <p>
          <label htmlFor="email">
            Your Email:
            <input
              aria-label="Your Email:"
              id="email"
              name="email"
              type="email"
            />
          </label>
        </p>
        <p>
          <label htmlFor="message">
            Message:
            <textarea
              aria-label="Message:"
              id="message"
              name="message"
            ></textarea>
          </label>
        </p>
        <p>
          <button type="submit">Send</button>
        </p>
      </form>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
